// The real definition of NativeApp is in the base app library which we shouldn't import from
// so we're defining what we need here.

type Destination = 'enterEmail' | 'contentNotFoundDialog';

type NativeApp = {
  getDeviceType: () => string,
  getDeviceId: () => string,
  isHosted: () => boolean,
  isReactNative: () => boolean,
  signOut: (reason?: string) => void,
  getJwtToken: () => string,
  getAuth0IdToken: () => string,
  getAuth0AccessToken: () => string,
  exitActivity: (destination?: Destination) => void,
  exit: () => void,
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default () => (window as Window).NativeApp as NativeApp
