/* global location */
// NOTE: This file is included in app.jsx, so please be careful to avoid importing large libraries

// totally legit email regex is really complicated. This is good enough for this use. It's the
// same regex used in HTML5 "email" type inputs via the W3C http://emailregex.com/
export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-z]*$/
export const UPPERCASE_REGEX = /^(.*[A-Z])/
export const LOWERCASE_REGEX = /^(.*[a-z])/
export const NUMBER_REGEX = /^(.*\d)/

export const MIN_PASSWORD_LENGTH = 14
export const MAX_PASSWORD_LENGTH = 64
export const MAX_INPUT_FIELD_LENGTH = 2000
export const MAX_SELECT_HEIGHT = 300
// Until the SSO efforts have pushed our password minimum to 10 everywhere, we want to continue
// to only require a length of 8 in our existing templates.
export const LEGACY_MIN_PASSWORD_LENGTH = 8
export const PHONE_PLACEHOLDER = '( 000 ) - 000 - 0000'

export const ASSESSMENT_FOR_INDICATION = {
  depression: 'phq9',
  social_anxiety: 'spin',
  generalized_anxiety: 'gad7',
}

export const INDICATION_FOR_ASSESSMENT = Object.entries(ASSESSMENT_FOR_INDICATION).reduce(
  (indications, [indication, assessment]) => ({ ...indications, [assessment]: indication }),
  {},
)

export const CLIENT_LIST_FAKE_WORKLIST = 'CLIENT_LIST'

export const EXPECTED_REDUCTIONS = {
  spin: 0.55,
  phq8: 0.45,
  phq9: 0.45,
  gad7: 0.55,
}

export const IOS_APP_URL = 'https://itunes.apple.com/us/app/id1065052030?mt=8'
export const ANDROID_APP_URL = 'https://play.google.com/store/apps/details?id=com.joyable.joyableApp'

export const MARKETING_DOMAINS = ['https://ableto.com', 'https://www.ableto.com']
export const ENVIRONMENT_LEVEL_DOMAINS = {
  local: 'http://localhost:5000',
  dev: 'https://consumer.dev.ableto.com',
  dev2: 'https://consumer2.dev.ableto.com',
  sit: 'https://consumer.sit.ableto.com',
  uat: 'https://consumer.uat.ableto.com',
  stg: 'https://consumer.stg.ableto.com',
  prod: 'https://app.ableto.com/',
}

export const IS_ANDROID = /(android)/i.test(navigator.userAgent)
export const IS_IOS = /(iphone|ipad|ipod)/i.test(navigator.userAgent)

export const IS_PROD = process.env.NODE_ENV === 'production'
export const IS_DEBUG = process.env.DEBUG === true
export const IS_TEST = process.env.NODE_ENV === 'test'

export const SELF_CARE_MIN_AGE = 13
export const US_MIN_AGE = 14
export const EU_MIN_AGE = 16
export const CONNECT_DEFAULT_MIN_AGE = 18
export const DOB_MIN_YEAR = '1700-01-01'
export const US_COUNTRY = 'United States'

export const FIT_SLUG = 'fit'
export const FIT_RESULTS_SLUG = 'fit_results'
export const YOUR_FOCUS_SLUG = 'your_focus'

export const COOKIE_SECURE = location.protocol === 'https:'
export const COOKIE_SAME_SITE = COOKIE_SECURE ? 'None' : 'Lax'

export const IN_IFRAME = window.parent !== window

export const E2_USER_ROLES = [
  'therapist',
  'program_advisor',
  'coach',
  'coach_advisor',
  'therapy_reviewer',
  'admin',
  'tech_admin',
]

// List of roles that can act as coach
export const COACHING_ROLES = ['coach', 'coach_advisor']

// Labels for all types of call mediums
export const CALL_MEDIUM_LABELS = {
  twilio_video: 'Video',
  video: 'Video',
  call: 'Phone',
  chat: 'Chat',
}

// Attribute for Pages to not use default label for title
export const ASYNC_PAGE_TITLE = 'async_page_title'

export const SMART_TIMING = 'default_timing'
