import inflection from 'inflection'
import isString from 'lodash/isString'
import flatten from 'lodash/flatten'
import { ApiKey, ApiState, ApiSuccessValue, ApiValue } from './types'

type NormalizedModels = { normalized: ApiSuccessValue[], modelMap: ApiSuccessValue[] }

export const EMPTY_RESULT = Symbol('Empty Result')
export const DELETE = Symbol('DELETE')

const normalizedValue = (key: ApiKey, value: ApiValue | ApiValue[], id?: ApiKey): ApiSuccessValue => {
  if (FORCE_SINGULAR.includes(key)) {
    return {
      key: [inflection.singularize(key as string)],
      value: Array.isArray(value) ? value[0] : value,
    }
  }

  const returnedKey = [key]
  if ((value as ApiState).id != null) returnedKey.push((value as ApiState).id as ApiKey)
  else if (id != null) returnedKey.push(id)
  return { key: returnedKey, value }
}

// A list of sideloaded models that we assume are singular, so they should be stored under a
// singular model name. This is a pretty gross hack, and comes out of some inconsistencies in how
// this client treats singular vs plural models. This hack is done because this system is getting
// too arcane to refactor safely. Unit tests and then a middleware level redesign are needed.
const FORCE_SINGULAR: ApiKey[] = ['promotions', 'landing_configs', 'indicator_sets', 'connects', 'previous_therapists']

// TODO (NJC): OMG SPECS
// TODO (NJC): The wonky EMPTY_RESULT handling might be able to go away now that we're not
// silently ignoring 404s
export function getNormalizedModels (
  json: Record<string, any>,
  { modelMapKey, resource, id }: { modelMapKey?: ApiKey[], resource?: string, id?: ApiKey } = {},
): NormalizedModels {
  const keys = Object.keys(json)

  const modelMap: ApiSuccessValue[] = []
  const normalized = flatten(keys.map(modelName => {
    const value = json[modelName] as ApiValue | ApiValue[]
    if (!value) return null

    if (Array.isArray(value) || (value as ApiState).id != null || id != null) {
      modelName = inflection.pluralize(modelName)
    }

    if (Array.isArray(value) && !FORCE_SINGULAR.includes(modelName)) {
      if (value.length === 0) {
        if (modelMapKey != null) {
          modelMap.push({ key: modelMapKey.concat([modelName]), value: EMPTY_RESULT })
        }
        return null
      } else if ((value[0] as ApiState).id) {
        // assume that if the first value has an id, they all do.
        return value.map(val => {
          const value = modelMapMember(modelMapKey, modelName, val)
          if (value != null) modelMap.push(value)
          return normalizedValue(modelName, val, id)
        })
      } else {
        // the "array of real values" case - does not currently get put into any modelMaps
        return normalizedValue(modelName, value, id)
      }
    } else if (isString(value)) {
      // Handles a rare case where a single string value can fall through the cracks in the final
      // return below. This was added specifically to address generated_at in socket channel
      // updates. There is likely a more elegant solution but until we have tests to check that
      // I don't break something in here by screwing with the overall structure, this will have to
      // do.
      return normalizedValue(modelName, value)
    }

    // special case for `<resource>/me` fetches
    // TODO (NJC): adapt to the newer `?user_id=me` syntax for this... might be able to rely on the
    // modelMapKey system for this as well.
    if (resource !== 'articles' && resource === modelName && id === 'me') {
      return [
        normalizedValue(modelName, value),
        { key: [modelName, 'me'], value: (value as ApiState).id },
      ]
    }

    const modelMapValue = modelMapMember(modelMapKey, modelName, value)
    if (modelMapValue != null) modelMap.push(modelMapValue)

    // return null because its going on the modelMap directly
    return modelMapKey != null && (value as ApiState).id == null
      ? null
      : normalizedValue(modelName, value, id)
  })).filter(value => value != null) as ApiSuccessValue[]

  if (keys.length === 0 && modelMapKey != null && resource != null) {
    // if we got back an empty response, create an empty value for this modelMapKey
    modelMap.push(modelMapMember(modelMapKey, resource, EMPTY_RESULT) as ApiSuccessValue)
  }

  return { modelMap: modelMap.filter(value => value != null), normalized }
}

// TODO (NJC): This will no longer need to be exported when the admin api's normalize method
// is removed.
export function modelMapMember (
  modelMapKey: ApiKey[] | undefined | null,
  modelName: string,
  value: ApiValue | symbol | ApiValue[],
): ApiSuccessValue | null {
  if (modelMapKey == null) return null

  const modelMapKeyProp = modelMapKey[0]
  const modelMapId = ((value as ApiState)[modelMapKeyProp] ??
    (modelMapKey.length > 1 ? modelMapKey[1] : null)) as ApiKey
  if (modelMapId == null) return null

  return {
    key: [modelMapKeyProp, modelMapId, modelName],
    value: (value as ApiState).id != null ? { id: (value as ApiState).id } : value,
  }
}
