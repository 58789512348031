import { Enum } from '@app/js/lib/enum'
import get from 'lodash/get'
import { createSelector } from 'reselect'
import ApiFetch, { ApiFetchOptions } from './ApiFetch'
import ApiModel from './ApiModel'
import { ApiState, Pending, PendingErrorState } from './types'

const PENDING_SOURCE = (method: string) =>
  (state: ApiState) => (state.apiPending as ApiState)[method] as Record<string, Pending>

export default class Action extends Enum {
  // we don't currently support update/replace in any of our APIs.
  static CREATE = new Action('POST', true)
  static READ = new Action('GET')
  static UPDATE_MODIFY = new Action('PUT', true)
  static DESTROY = new Action('DELETE')
  static _ = this.closeEnum()

  static isActing = (pending?: Pending) =>
    pending != null && (pending as PendingErrorState).failed == null

  static isNotFound = (pending?: Pending) =>
    pending == null ? false : get(pending, 'failed.response.status') === 404

  static hasFailed = (pending: Pending) => Action.getFailed(pending) != null

  static getFailed = (pending?: Pending) => {
    if ((pending as PendingErrorState)?.failed == null) return undefined
    return pending && (pending as PendingErrorState).failed
  }

  static getError = (pending?: Pending) => this.getFailed(pending)?.error

  static getErrorMessage = (
    pending?: Pending,
    defaultMessage = 'Something bad happened',
  ): string | undefined => {
    const error = this.getError(pending)
    return error == null ? undefined : (error.message ?? defaultMessage)
  }

  static getErrorReason = (pending?: Pending) => this.getError(pending)?.reason

  static getErrorDetails = (pending?: Pending) =>
    this.getError(pending)?.reason as Record<string, any> | undefined

  private readonly _pendingSelectorMap: Map<string, (state: ApiState) => Pending | undefined>

  method: string
  hasBody: boolean

  constructor (method: string, hasBody = false) {
    super()
    this.method = method
    this.hasBody = hasBody
    this._pendingSelectorMap = new Map()
  }

  get pendingSource () {
    return PENDING_SOURCE(this.method)
  }

  /**
   * Returns the pending state for the given request url.
   */
  getPending (state: ApiState, url: string) {
    let selector = this._pendingSelectorMap.get(url)
    if (selector == null) {
      selector = createSelector(
        [this.pendingSource],
        pending => pending?.[url] as undefined | RequestInit | PendingErrorState,
      )
      this._pendingSelectorMap.set(url, selector)
    }
    return selector(state)
  }

  /**
   * Returns true if the given url's request is currently in progress
   */
  isActing = (state: ApiState, url: string) => Action.isActing(this.getPending(state, url))

  getPendingFailed (state: ApiState, url: string) {
    return Action.getFailed(this.getPending(state, url))
  }

  /**
   * Returns the error message if the given url's request failed.
   */
  getErrorMessage = (state: ApiState, url: string, defaultMessage = 'Something bad happened') =>
    Action.getErrorMessage(this.getPending(state, url), defaultMessage)

  /**
   * Returns the error reason if the given url's request failed and there's a reason given by the
   * server
   */
  getErrorReason = (state: ApiState, url: string) => Action.getErrorReason(this.getPending(state, url))

  /**
   * Returns the error details if the given url's request failed and there are details given by the
   * server
   */
  getErrorDetails = (state: ApiState, url: string) => Action.getErrorDetails(this.getPending(state, url))

  /**
   * Returns a selector that returns true if the given url returned a 404.
   */
  isNotFound = (state: ApiState, url: string) => Action.isNotFound(this.getPending(state, url))

  /**
   * Returns the request type for this model.
   */
  requestType (model: ApiModel) {
    return `${model.actionPrefix}_${this.enumKey}_REQUEST`
  }

  /**
   * Returns the success type for this action.
   */
  successType (model: ApiModel) {
    return `${model.actionPrefix}_${this.enumKey}_SUCCESS`
  }

  /**
   * Returns the failure type for this action.
   */
  failureType (model: ApiModel) {
    return `${model.actionPrefix}_${this.enumKey}_FAILURE`
  }

  /**
   * Get the request/success/failure actions for the given ApiModel.
   */
  getTypes (model: ApiModel) {
    return [this.requestType(model), this.successType(model), this.failureType(model)]
  }

  /**
   * Returns the action required to clear the pending status for a request on this action with the
   * given model and URL.
   */
  getClearPendingAction (model: ApiModel, url: string) {
    return {
      type: `${model.actionPrefix}_${this.enumKey}_CLEAR_PENDING`,
      options: { method: this.method },
      url,
    }
  }

  createFetch (model: ApiModel, options: ApiFetchOptions) {
    return new ApiFetch(model, this, options)
  }
}
