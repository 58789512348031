// Taken from https://github.com/rauschma/enumify. We don't use the library directly because
// something about how it's built isn't compatible with IE11, even if we try to run it through
// babel ourselves.

export class Enum {
  static enumKeys: string[]
  private static enumValues: Enum[]

  enumKey?: string
  enumOrdinal?: number

  static closeEnum () {
    const enumKeys: string[] = []
    const enumValues: Enum[] = []
    // Traverse the enum entries
    for (const [key, value] of Object.entries(this)) {
      if (value instanceof Enum) {
        enumKeys.push(key)
        value.enumKey = key
        value.enumOrdinal = enumValues.length
        enumValues.push(value)
      }
    }
    // Important: only add more static properties *after* processing the enum entries
    this.enumKeys = enumKeys
    this.enumValues = enumValues
    // TODO: prevent instantiation now. Freeze `this`?
  }

  /** Use case: parsing enum values */
  static enumValueOf (str: string) {
    const index = this.enumKeys.indexOf(str)
    if (index >= 0) {
      return this.enumValues[index]
    }
    return undefined
  }

  static [Symbol.iterator] () {
    return this.enumValues[Symbol.iterator]()
  }

  toString () {
    return `${this.constructor.name}.${this.enumKey}`
  }
}
