export type Logger = {
  info: (...args: unknown[]) => void,
  debug: (...args: unknown[]) => void,
  warn: (...args: unknown[]) => void,
  error: (...args: unknown[]) => void,
}

let logTarget: Logger = console

export function setLoggingTarget (target: Logger) {
  logTarget = target
}

const Log: Logger = {
  info (...args) {
    logTarget.info(...args)
  },

  debug (...args) {
    logTarget.debug(...args)
  },

  warn (...args) {
    logTarget.warn(...args)
  },

  error (...args) {
    logTarget.error(...args)
  },
}

export default Log
