type NativeiOSApp = {
  getDeviceType: () => string,
  isHosted: () => boolean,
  onNoNextPath: () => void,
  startLoading: () => void,
  stopLoading: () => void,
  getJwtToken: () => string | null | undefined,
  getAuth0IdToken: () => string,
  getAuth0AccessToken: () => string,
  getIdpToken: () => string | null | undefined,
  exitActivity: (destination?: string) => void,
  sendWebViewAuthPayload: (authPayload: any, email: string) => void,
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default () => (window as Window).NativeiOSApp as NativeiOSApp
