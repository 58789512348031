import queryString from 'query-string'
import { Action as ActionType, Store } from 'redux'
import isEqual from 'lodash/isEqual'
import Action from './Action'
import ApiFetch, { ApiFetchOptions } from './ApiFetch'
import Log from './log'
import { ApiAction, ApiKey, ApiState, isPruneAction, PruneAction } from './types'

export type FailureLoggingFilter = (
  json: Record<string, any> | null,
  response: Response,
  fetch: ApiFetch,
) => boolean

export type PathOptions = {
  id?: string | number,
  query?: Record<string, any>,
}

type TrimCacheOptions = {
  keepKeys?: ApiKey[][],
  modelMapPruneKeys?: ApiKey[][],
  additionalActionValues?: { [k:string]: any },
}

export const PRUNE_API_TREE = 'PRUNE_API_TREE'

export const isPruneOf = (checkKey: ApiKey[]) => (action: ActionType<string>) =>
  isPruneAction(action) && action.pruneKeys.find(key => isEqual(key, checkKey)) != null

const apiProxyVersion = process.env.API_PROXY_VERSION || 'v1'

// Converts URLs to use the Apigee API proxy
// Example: https://consumer.dev.ableto.com/api/v1/foo
// Becomes: https://api.dev.ableto.com/consumer/v1/api/v1/foo
export const convertUrlToUseApiProxy = (url: string) => {
  const regex = /((?<consumer>consumer\d?)\.)?(?<env>\w+)\.(?<domain>ableto\.com)\/?(?<path>.*)/
  interface UrlGroups {
    consumer?: string,
    env: string,
    domain: string,
    path?: string,
  }
  const { consumer, env, domain, path } = { ...url.match(regex)?.groups as any } as UrlGroups

  if (domain !== 'ableto.com') {
    console.log(`convertUrlToUseApiProxy can only convert ableto.com URLs: ${url}`)
    return url
  }
  // production URLs will have app.ableto.com so env will equal "app"
  if (env === 'app') {
    return `https://api.ableto.com/consumer/${apiProxyVersion}/${path}`
  } else {
    return `https://api.${env}.ableto.com/${consumer}/${apiProxyVersion}/${path}`
  }
}

export default class ApiModel {
  private readonly _actionPrefix: string
  private readonly _baseUrl: string
  private readonly _failureLoggingFilter: FailureLoggingFilter
  private static _reduxStore: Store

  constructor (
    actionPrefix: string,
    baseUrl: string,
    failureLoggingFilter: FailureLoggingFilter = () => true,
  ) {
    this._actionPrefix = actionPrefix
    this._baseUrl = ApiModel.createEndpointUrl(baseUrl)
    this._failureLoggingFilter = failureLoggingFilter
  }

  /**
   * The store must be set during application bootstrap, before the first API call is made.
   */
  static set store (value) {
    this._reduxStore = value
  }

  static get store () {
    return this._reduxStore
  }

  static get reduxState () {
    return this._reduxStore.getState() as ApiState
  }

  static get dispatch () {
    return this._reduxStore.dispatch
  }

  static createEndpointUrl (endpoint) {
    return `${process.env.API_ROOT}${endpoint}`
  }

  /**
   * Clears the redux model cache for a given key.
   *
   * @param key Array: The path within the redux store's `api` tree to prune.
   *   ex: ApiModel.pruneCache('user_activities', 12345)
   *       ApiModel.pruneCache('coaches')
   */
  static pruneCache (...key: ApiKey[]) {
    ApiModel.trimCache([key])
  }

  /**
   * Similar to pruneCache, this causes some of the redux API store to get cleared out. This version
   * is more complex. It allows for multiple base keys to be included, additional action parameters
   * in case they're needed by other reducers and a whitelist of keys that should be left in place.
   *
   * @param pruneKeys An array of keys. Unless a sub key is listed in keepKeys, the entire tree
   * under each of these keys is removed.
   * @param keepKeys An array of whitelisted keys to leave in place in the store.
   * @param modelMapPruneKeys An array of keys. If supplied, the keys will be removed from all
   * matching entries in the modelMap. E.g. ['user_id', '*', 'messages'] will remove the message
   * key array from all user maps in the model map.
   * @param additionalActionValues Any additional values that should be included on this action.
   */
  static trimCache (
    pruneKeys: ApiKey[][],
    { keepKeys = [], modelMapPruneKeys = [], additionalActionValues = {} } = {} as TrimCacheOptions,
  ) {
    ApiModel.dispatch({
      type: PRUNE_API_TREE,
      pruneKeys,
      keepKeys,
      modelMapPruneKeys,
      ...additionalActionValues,
    } as PruneAction)
  }

  get actionPrefix () {
    return this._actionPrefix
  }

  get baseUrl () {
    return this._baseUrl
  }

  logFailure (
    json: Record<string, any> | null,
    response: Response,
    fetch: ApiFetch,
    message = 'Api request failed',
  ) {
    if (fetch.recordFailure(response) && this._failureLoggingFilter(json, response, fetch)) {
      Log.warn(`${message} [${fetch.options.method}, ${fetch.url}]`,
        JSON.stringify(json),
        fetch.networkFieldsForLog)
    }
  }

  getPath ({ id, query }: PathOptions) {
    let search = ''
    if (query != null) search = `?${queryString.stringify(query)}`

    id = id == null ? '' : `/${id}`
    return id + search
  }

  getUrl = (options: PathOptions = {}) => `${this.baseUrl}${this.getPath(options)}`

  create (data: Record<string, any>, options: ApiFetchOptions = {}) {
    return this.dispatchRequest(Action.CREATE, { ...options, data })
  }

  /**
   * @deprecated Prefer isCreatingSelector
   */
  isCreating = (state: ApiState, options: PathOptions = {}) => this.isCreatingSelector(options)(state)

  isCreatingSelector = (options: PathOptions = {}) => (state: ApiState) =>
    Action.CREATE.isActing(state, this.getUrl(options))

  getCreateErrorMessage = (state: ApiState, options: PathOptions = {}) =>
    Action.CREATE.getErrorMessage(state, this.getUrl(options))

  getCreateErrorReason = (state: ApiState, options: PathOptions = {}) =>
    Action.CREATE.getErrorReason(state, this.getUrl(options))

  getCreateErrorDetails = (state: ApiState, options: PathOptions = {}) =>
    Action.CREATE.getErrorDetails(state, this.getUrl(options))

  destroy (options: ApiFetchOptions = {}) {
    return this.dispatchRequest(Action.DESTROY, options)
  }

  /**
   * @deprecated Prefer isDestroyingSelector
   */
  isDestroying = (state: ApiState, options: PathOptions = {}) => this.isDestroyingSelector(options)(state)

  isDestroyingSelector = (options: PathOptions = {}) => (state: ApiState) =>
    Action.DESTROY.isActing(state, this.getUrl(options))

  getDestroyErrorMessage = (state: ApiState, options: PathOptions = {}) =>
    Action.DESTROY.getErrorMessage(state, this.getUrl(options))

  read (options: ApiFetchOptions = {}) {
    return this.dispatchRequest(Action.READ, options)
  }

  /**
   * @deprecated Prefer isReadingSelector
   */
  isReading = (state: ApiState, options: PathOptions = {}) => this.isReadingSelector(options)(state)

  isReadingSelector = (options: PathOptions = {}) => (state: ApiState) =>
    Action.READ.isActing(state, this.getUrl(options))

  getReadErrorMessage = (state: ApiState, options: PathOptions = {}) =>
    Action.READ.getErrorMessage(state, this.getUrl(options))

  getReadErrorReason = (state: ApiState, options: PathOptions = {}) =>
    Action.READ.getErrorReason(state, this.getUrl(options))

  isNotFoundSelector = (options: PathOptions = {}) => (state: ApiState) =>
    Action.READ.isNotFound(state, this.getUrl(options))

  /**
   * @deprecated Prefer isNotFoundSelector
   */
  isNotFound = (state: ApiState, options: PathOptions = {}) =>
    Action.READ.isNotFound(state, this.getUrl(options))

  updateModify (data: Record<string, any>, options: ApiFetchOptions = {}) {
    return this.dispatchRequest(Action.UPDATE_MODIFY, { ...options, data })
  }

  /**
   * @deprecated. Prefer isUpdatingSelector.
   */
  isUpdating = (state: ApiState, options: PathOptions = {}) => this.isUpdatingSelector(options)(state)

  isUpdatingSelector = (options: PathOptions = {}) => (state: ApiState) =>
    Action.UPDATE_MODIFY.isActing(state, this.getUrl(options))

  getUpdateErrorMessage = (state: ApiState, options: PathOptions = {}) =>
    Action.UPDATE_MODIFY.getErrorMessage(state, this.getUrl(options))

  getUpdateErrorReason = (state: ApiState, options: PathOptions = {}) =>
    Action.UPDATE_MODIFY.getErrorReason(state, this.getUrl(options))

  getUpdateErrorDetails = (state: ApiState, options: PathOptions = {}) =>
    Action.UPDATE_MODIFY.getErrorDetails(state, this.getUrl(options))

  private dispatchRequest (action: Action, options: ApiFetchOptions) {
    return ApiModel.dispatch(
      action.createFetch(this, options).dispatchObject,
    ) as unknown as Promise<ApiAction>
  }
}
