import queryString from 'query-string'
import { IS_ANDROID, ANDROID_APP_URL, IS_IOS, IOS_APP_URL } from '@app/js/constants'
import { convertUrlToUseApiProxy } from 'joyable-js-api/src/ApiModel'

const prod = process.env.NODE_ENV === 'production'

// A couple of old campaigns have multiple landing pages with one campaign, which is awkward to
// support at the campaign level and we're not sure we'll need it moving forward, so for now
// we're redirecting the old campaigns and not adding new extra features to the campaign system.
const LANDING_REDIRECTS = {
  '/tpg-benefit': '/tpg',
  '/thefool': '/fool',
  '/espanol': '/español',
}

// Quick and dirty redirects when we temporarily want a partner landing page to go somewhere else
const EXTERNAL_REDIRECTS = {
}

// Activities that have been moved to the generic activity system, and should have a redirect
// in case someone has bookmarked the old URL
const ACTIVITY_REDIRECTS = [
  'schedule-call',
  'what-to-expect',
  'coach-call-satisfaction',
  'score-compare',
  'reengage-breathing',
  'distraction-inventory',
  'anchor-meditation',
  'rewarding-focus',
  'distraction-recap',
  'maintaining-calm',
  'guided-visualization',
  'diaphraghmatic-breathing',
  'calm-under-pressure-recap',
  'optimal-performance',
  'attention-and-interest',
  'acceptance-meditation',
  'gratitude',
  'walking-meditation',
  'mindfulness-anchor-meditation',
  'thought-checking',
  'reassess',
  'intro-to-joyable-v2',
  'what-is-anxiety',
  'how-anxiety-works',
  'looking-forward',
  'intro-to-gad',
  'introduction-to-joyable',
  'controlled-breathing',
  'thoughts-feelings-actions',
  'the-spiral-of-anxiety',
  'release-tension',
  'gad7',
  'our-anxious-thoughts',
  'the-first-c-catch-it',
  'the-second-c-check-it',
  'the-third-c-change-it',
  'mindfulness-concentration',
  'noticing-avoidance-actions',
  'first-behavioral-experiment',
  'revisiting-pmr',
  'worry-exposures',
  'your-first-focus',
  'worry-exposure',
  'mindfulness-clarity',
  'worry-exposure-two',
  'mindfulness-acceptance',
  'gad-core-beliefs',
  'gad-maintaining-gains',
  'emotions-in-the-body',
  'second-behavioral-experiment',
  'debrief-your-behavioral-experiment-with-tips',
  'written-worry-exposure',
  'first-worry-exposure',
  'second-worry-exposure',
  'progressive-muscle-relaxation',
  'debrief-your-behavioral-experiment',
  'plan-a-behavioral-experiment',
  'choose-your-next-focus',
  'spin',
  'exposure-intro',
  'social-anxiety',
  'first-challenge-plan',
  'challenge-plan',
  'challenge-recap',
  'your-focus',
  'fear-hierarchy',
  'mood-tracker',
  'behavioral-activation',
  'first-activation-prep',
  'first-activation-recap',
  'second-activation-prep',
  'second-activation-recap',
  'build-your-path',
  'activation-prep',
  'activation-recap',
  'next-step',
  'stress-spiral',
  'thought-distortions',
  'body-scan',
  'interactive-pmr',
  'square-breathing',
  'catastrophizing',
  'mind-reading',
  'overcome-avoidance-actions',
  'fortune-telling',
  'cognitive-defusion',
  'video-pmr',
  'audio-pmr',
  'basic-worry-exposure',
  'personalizing',
  'basic-spin',
  'basic-gad7',
  'basic-phq9',
  'what-is-therapy',
  'prepare-for-your-call',
  'concierge-satisfaction',
  'reflection-prompt',
  'how-we-find-provider',
  'provider-review',
  'script-you-can-use',
  'consultation-call-topics',
  'finding-time',
  'choosing-whats-essential',
  'rate-provider',
  'pbh-controlled-breathing',
  'pbh-phq9',
  'pbh-map-your-journey',
  'pbh-mood-monitor',
  'pbh-muscle-relaxation',
  'pbh-behavioral-activation',
  'pbh-thinking-issues',
  'pbh-three-cs',
  'pbh-core-beliefs-psych-ed',
  'pbh-core-beliefs-tool',
  'pbh-skills-review',
  'pbh-learn-three-cs',
  'pbh-learn-behavioral-activation',
  'pbh-journey-reflection',
  'pbh-moving-forward',
  'pbh-placeholder',
  'how-ableto-works',
]

const CALENDAR_AUTHORIZATION = '/calendar-authorization-redirect'

if (prod && window.location.protocol === 'http:') {
  window.location = window.location.href.replace('http:', 'https:')
} else if (prod && window.location.host === 'www.joyable.com') {
  window.location = window.location.href.replace('www.joyable.com', 'joyable.com')
} else if (queryString.parse(window.location.search).deep_link === 'true') {
  if (IS_ANDROID) {
    window.location = ANDROID_APP_URL
  } else if (IS_IOS) {
    window.location = IOS_APP_URL
  }
} else if (LANDING_REDIRECTS[window.location.pathname] != null) {
  window.location = window.location.href.replace(
    window.location.pathname,
    LANDING_REDIRECTS[window.location.pathname],
  )
} else if (EXTERNAL_REDIRECTS[window.location.pathname] != null) {
  window.location.href = EXTERNAL_REDIRECTS[window.location.pathname]
} else if (ACTIVITY_REDIRECTS.includes(window.location.pathname.split('/')[1])) {
  window.location = window.location.href.replace(
    window.location.pathname,
    `/activities${window.location.pathname}`,
  )
} else if (window.location.pathname === CALENDAR_AUTHORIZATION) {
  // The final step of the OAuth2 process with Google is to get redirected back to our site with
  // some query params that establish the authorization tokens. Google doesn't allow any kind of
  // wild carding dynamic portions of the allowed URLs, so we're sending the coach to this
  // CALENDAR_AUTHORIZATION path so that our JS code here can pull the JWT token from local
  // storage and append it to the query params that go to the server so that the server can
  // authentication the coach that this OAuth2 grant is associated with.
  const { url, query } = queryString.parseUrl(window.location.href)
  const redirectUrl = url.replace(CALENDAR_AUTHORIZATION, '/admin/api/v1/calendar_access_oauth2')
  query.token = window.localStorage.getItem('ableto-jwt')
  window.location = convertUrlToUseApiProxy(`${redirectUrl}?${queryString.stringify(query)}`)
}
