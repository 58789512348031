/* global NativeApp */
/* global NativeiOSApp */
import firebase from 'firebase/app'
import 'firebase/auth'
import Log from './log'

let FIREBASE_AUTH = null

function initialize (apiKey, authDomain) {
  const app = firebase.initializeApp({ apiKey, authDomain })
  FIREBASE_AUTH = app.auth()
}

/**
 * Creates a user in IdP. It is expected that the user already exists in the consumer database
 * before this action is taken.
 *
 * If the user already exists with a password in IdP, this logs them in instead of trying to
 * create them fresh. That implies that if this user was created in IdP by other means, they must
 * have the correct email/password combo here to succeed.
 *
 * @param email User's email address
 * @param password User's password
 * @returns {Promise<string>} Resolves to the current ID token
 */
export async function createUserInIdp (email, password) {
  const { currentUser } = FIREBASE_AUTH

  if (currentUser != null) {
    try {
      await currentUser.updatePassword(password)
      return currentUser.getIdToken(true)
    } catch (error) {
      Log.warn('Update password failed, trying to reauthenticate', error)
      const credential = getCredentialWithLink(email, window.location.href)
      currentUser.reauthenticateWithCredential(credential).then(async () => {
        Log.info('reauthentication successful')
        await currentUser.updatePassword(password)
        return currentUser.getIdToken(true)
      })
    }
  }

  const credential = await FIREBASE_AUTH.createUserWithEmailAndPassword(email, password)
  return await credential.user.getIdToken(true)
}

export function getCredentialWithLink (email, emailLink) {
  return firebase.auth.EmailAuthProvider.credentialWithLink(email, emailLink)
}

/**
 * Retrieves the ID token for the user identified by this email/password. If the user is
 * already logged in in IdP, we don't re-authenticate.
 *
 * @param email User's email address
 * @param password User's password
 * @returns {Promise<string|*>} Resolves to the current ID token
 */
async function getGoogleToken (email, password) {
  const credential = await FIREBASE_AUTH.signInWithEmailAndPassword(email, password)
  return credential.user.getIdToken(true)
}

function isSignInWithEmailLink (href) {
  return FIREBASE_AUTH.isSignInWithEmailLink(href)
}

function signInWithEmailLink (email, href) {
  return FIREBASE_AUTH.signInWithEmailLink(email, href)
}

async function checkActionCode (code) {
  try {
    return await FIREBASE_AUTH.checkActionCode(code)
  } catch (e) {
    throw new Error('invalid code')
  }
}

async function getIdpToken () {
  if (NativeApp.isHosted()) return NativeApp.getIdpToken()

  if (NativeiOSApp.isHosted() && NativeiOSApp.getIdpToken) {
    return NativeiOSApp.getIdpToken()
  }

  if (FIREBASE_AUTH.currentUser == null) return

  return await FIREBASE_AUTH.currentUser.getIdToken()
}

async function signInViaGIDP (token) {
  return await FIREBASE_AUTH.signInWithCustomToken(token)
}

function getCurrentUser () {
  if (!NativeApp.isHosted()) return FIREBASE_AUTH.currentUser

  const idpUser = NativeApp.getIdpUser()
  if (idpUser == null) return null

  return JSON.parse(idpUser)
}

function callOnUserLoaded (callback, options) {
  FIREBASE_AUTH.onAuthStateChanged((user) => {
    if (user) {
      callback(user, options)
    }
  })
}

export const Firebase = {
  initialize,

  get auth () {
    return FIREBASE_AUTH
  },

  createUserInIdp,
  getGoogleToken,
  isSignInWithEmailLink,
  signInWithEmailLink,
  checkActionCode,
  signInViaGIDP,
  getCurrentUser,
  getCredentialWithLink,
  getIdpToken,
  callOnUserLoaded,
}
